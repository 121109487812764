import { computed, defineComponent, PropType } from 'vue'
import {
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator,
  InlichtingRubriek,
  LeegstaandeEnVerwaarloosdeGebouwenInlichting,
  LeegstaandeEnVerwaarloosdeGebouwenInlichtingInventarisStatus,
  LeegstaandeEnVerwaarloosdeGebouwenInlichtingInventarisType,
  LeegstaandeEnVerwaarloosdeGebouwenInlichtingOnroerendGoedType,
  LeegstaandeEnVerwaarloosdeGebouwenInput,
  LeegstaandeEnVerwaarloosdeGebouwenInputInventarisStatus,
  LeegstaandeEnVerwaarloosdeGebouwenInputInventarisType,
  LeegstaandeEnVerwaarloosdeGebouwenInputOnroerendGoedType,
  RestAdres,
  RestLocatie,
  TypeInlichting, VipInlichtingDiscriminator, VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import EventBus from '@/infrastructure/events/event-bus'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'

function mapInlichtingToInput (vipInlichting?: LeegstaandeEnVerwaarloosdeGebouwenInlichting): LeegstaandeEnVerwaarloosdeGebouwenInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingInputInlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    referentie: vipInlichting?.referentie,
    inventarisType: vipInlichting?.inventarisType as unknown as LeegstaandeEnVerwaarloosdeGebouwenInputInventarisType,
    onroerendGoedType: vipInlichting?.onroerendGoedType as unknown as LeegstaandeEnVerwaarloosdeGebouwenInputOnroerendGoedType,
    inventarisStatus: vipInlichting?.inventarisStatus as unknown as LeegstaandeEnVerwaarloosdeGebouwenInputInventarisStatus,
    opgenomenSinds: vipInlichting?.opgenomenSinds,
    locatie: { adres: vipInlichting?.locatie?.adres ?? {} as RestAdres, beschrijving: vipInlichting?.locatie?.beschrijving } as RestLocatie,
    externeDocumentatie: vipInlichting?.externeDocumentatie,
    discriminator: VipInlichtingDiscriminator.LEEGSTAANDE_EN_VERWAARLOOSDE_GEBOUWEN_V1,
    inlichtingType: VipInlichtingType.LEEGSTAANDE_EN_VERWAARLOOSDE_GEBOUWEN,
    bijlagen: vipInlichting?.bijlagen,
  } as LeegstaandeEnVerwaarloosdeGebouwenInput
}

function mapInputToInlichting (input: LeegstaandeEnVerwaarloosdeGebouwenInput, inlichtingId: string): LeegstaandeEnVerwaarloosdeGebouwenInlichting {
  const inlichting = {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.LEEGSTAANDE_EN_VERWAARLOOSDE_GEBOUWEN,
    externeDocumentatie: input.externeDocumentatie,
    bijlagen: input.bijlagen,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.GROND_EN_PANDENBELEID
  } as LeegstaandeEnVerwaarloosdeGebouwenInlichting
  if (inlichting.inlichtingenIndicator === InlichtingenIndicator.JA) {
    inlichting.referentie = input.referentie
    inlichting.inventarisType = input.inventarisType as unknown as LeegstaandeEnVerwaarloosdeGebouwenInlichtingInventarisType
    inlichting.onroerendGoedType = input.onroerendGoedType as unknown as LeegstaandeEnVerwaarloosdeGebouwenInlichtingOnroerendGoedType
    inlichting.inventarisStatus = input.inventarisStatus as unknown as LeegstaandeEnVerwaarloosdeGebouwenInlichtingInventarisStatus
    inlichting.opgenomenSinds = input.opgenomenSinds
    inlichting.locatie = input.locatie
  }
  return inlichting
}

export default defineComponent({
  name: 'LeegstaandeEnVerwaarloosdeGebouwen',
  components: { VipInlichtingVanToepassing },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      isNietGekend,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      bijlagenSaved,
      bijlageDeleted,
      downloadUrl
    } = useEditableInlichting(
      props.inlichting ??
        ({ inlichtingType: VipInlichtingType.LEEGSTAANDE_EN_VERWAARLOOSDE_GEBOUWEN } as unknown as LeegstaandeEnVerwaarloosdeGebouwenInlichting),
      mapInlichtingToInput,
      mapInputToInlichting
    )

    const inlichtingType = TypeInlichting.LeegstaandeEnVerwaarloosdeGebouwen
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)
    const hasAddress = computed(() => {
      return !!inlichtingInput.value.locatie?.adres?.straat || !!inlichtingInput.value.locatie?.adres?.huisnummer || !!inlichtingInput.value.locatie?.adres.busnummer ||
          !!inlichtingInput.value.locatie?.adres?.postcode || !!inlichtingInput.value.locatie?.adres?.gemeente
    })
    const showAddress = computed(() => {
      return !!vipInlichting.value.locatie?.adres?.straat || !!vipInlichting.value.locatie?.adres?.huisnummer || !!vipInlichting.value.locatie?.adres?.busnummer ||
          !!vipInlichting.value.locatie?.adres?.postcode || !!vipInlichting.value.locatie?.adres?.gemeente
    })

    const getTitle = (inventarisType: LeegstaandeEnVerwaarloosdeGebouwenInputInventarisType, onroerendGoedType: LeegstaandeEnVerwaarloosdeGebouwenInputOnroerendGoedType): string => {
      let title1 = ''
      let title2 = ''
      switch (inventarisType) {
        case LeegstaandeEnVerwaarloosdeGebouwenInputInventarisType.LEEGSTAND_EN_OF_VERWAARLOZING: {
          title1 = 'Leegstaand'
          title2 = ' en/of verwaarloosd'
          break
        }
        case LeegstaandeEnVerwaarloosdeGebouwenInputInventarisType.VERWAARLOZING:
          title1 = 'Verwaarloosd'
          break
        case LeegstaandeEnVerwaarloosdeGebouwenInputInventarisType.LEEGSTAND:
          title1 = 'Leegstaand'
          break
      }
      switch (onroerendGoedType) {
        case LeegstaandeEnVerwaarloosdeGebouwenInputOnroerendGoedType.BEDRIJFSRUIMTE_KLEINER_DAN_500_VIERKANTE_METER:
          return title1 + title2 + ' bedrijfsgebouw kleiner dan 500 m²'
        case LeegstaandeEnVerwaarloosdeGebouwenInputOnroerendGoedType.GEBOUW:
          return title1 + title2 + ' gebouw'
        case LeegstaandeEnVerwaarloosdeGebouwenInputOnroerendGoedType.HANDELSRUIMTE:
          return title1 + 'e' + (title2 ? title2 + 'e' : '') + ' handelsruimte'
        case LeegstaandeEnVerwaarloosdeGebouwenInputOnroerendGoedType.KAMER:
          return title1 + 'e' + (title2 ? title2 + 'e' : '') + ' kamer'
        case LeegstaandeEnVerwaarloosdeGebouwenInputOnroerendGoedType.WONING:
          return title1 + 'e' + (title2 ? title2 + 'e' : '') + ' woning'
      }
    }

    const options = new Map([
      ['inventarisType',
        new Map([
          ['LEEGSTAND_EN_OF_VERWAARLOZING', 'Leegstand en/of verwaarlozing'],
          ['VERWAARLOZING', 'Verwaarlozing'],
          ['LEEGSTAND', 'Leegstand']
        ])
      ],
      ['onroerendGoedType',
        new Map([
          ['BEDRIJFSRUIMTE_KLEINER_DAN_500_VIERKANTE_METER', 'Bedrijfsruimte < 500m²","Bedrijfsruimte kleiner dan 500m²'],
          ['GEBOUW', 'Gebouw'],
          ['HANDELSRUIMTE', 'Handelsruimte'],
          ['KAMER', 'Kamer'],
          ['WONING', 'Woning'],
        ])
      ],
      ['inventarisStatus',
        new Map([
          ['ONDERZOEK_LOPENDE', 'Onderzoek lopende'],
          ['OPGENOMEN_IN_INVENTARIS', 'Opgenomen in inventaris']
        ])
      ]
    ])

    const getLabel = (optionKey: string, option: string): string => {
      const optionMap = options.get(optionKey)
      return optionMap.get(option)
    }

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!!inlichtingInput.value.inventarisType === false) {
        errors.set('inventaris-type', 'Type inventaris is verplicht')
      }
      if (!!inlichtingInput.value.onroerendGoedType === false) {
        errors.set('onroerend-goed-type', 'Type onroerend goed is verplicht')
      }
      if (hasAddress.value) {
        if (!!inlichtingInput.value.locatie.adres.straat === false) {
          errors.set('locatie-straat', 'Straat is verplicht')
        }
        if (!!inlichtingInput.value.locatie.adres.huisnummer === false) {
          errors.set('locatie-huisnummer', 'Nummer is verplicht')
        }
        if (!!inlichtingInput.value.locatie.adres.postcode === false) {
          errors.set('locatie-postcode', 'Postcode is verplicht')
        }
        if (!!inlichtingInput.value.locatie.adres.gemeente === false) {
          errors.set('locatie-gemeente', 'Gemeente is verplicht')
        }
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    const parsedInput = computed(() => {
      const inputClone = structuredClone(inlichtingInput.value)
      if (!hasAddress.value) {
        if (!!inlichtingInput.value.locatie?.beschrijving === false) {
          inputClone.locatie = null
        } else {
          inputClone.locatie.adres = null
        }
      } else {
        inputClone.locatie = { adres: inlichtingInput.value.locatie.adres }
        if (inlichtingInput.value.locatie?.beschrijving) {
          inputClone.locatie.beschrijving = inlichtingInput.value.locatie.beschrijving
        }
      }
      if (inlichtingInput.value.inlichtingenIndicator === InlichtingInputInlichtingenIndicator.NEE) {
        inputClone.locatie = null
      }
      return inputClone
    })

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      options,
      hasAddress,
      showAddress,
      isNietGekend,
      parsedInput,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      formatNumber,
      formatDate,
      getTitle,
      getLabel
    }
  }
})
