import { computed, defineComponent, PropType, ref } from 'vue'
import {
  BronInformatie,
  DossierStatus,
  GewestelijkRuimtelijkUitvoeringsplanInlichting,
  GewestelijkRuimtelijkUitvoeringsplanInput,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator,
  InlichtingRubriek,
  Planfase,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import RuimtelijkUitvoeringsplan
  from '@/components/vip-inlichting/new-version/components/inlichtingen/overheidsplannen/ruimtelijk-uitvoeringsplan/ruimtelijk-uitvoeringsplan.vue'

function mapInlichtingToInput (vipInlichting?: GewestelijkRuimtelijkUitvoeringsplanInlichting): GewestelijkRuimtelijkUitvoeringsplanInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingInputInlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    referentie: vipInlichting?.referentie,
    beschrijving: vipInlichting?.beschrijving,
    planfase: vipInlichting?.planfase ?? {} as Planfase,
    externeDocumentatie: vipInlichting?.externeDocumentatie,
    bestemmingen: vipInlichting?.bestemmingen,
    discriminator: VipInlichtingDiscriminator.GEWESTELIJK_RUIMTELIJK_UITVOERINGSPLAN_V1,
    inlichtingType: VipInlichtingType.GEWESTELIJK_RUIMTELIJK_UITVOERINGSPLAN
  } as GewestelijkRuimtelijkUitvoeringsplanInput
}

function mapInputToInlichting (input: GewestelijkRuimtelijkUitvoeringsplanInput, inlichtingId: string): GewestelijkRuimtelijkUitvoeringsplanInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.GEWESTELIJK_RUIMTELIJK_UITVOERINGSPLAN,
    version: 1,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.OVERHEIDSPLANNEN,
    referentie: input.referentie,
    beschrijving: input.beschrijving,
    planfase: input.planfase,
    externeDocumentatie: input.externeDocumentatie,
    bestemmingen: input.bestemmingen
  } as GewestelijkRuimtelijkUitvoeringsplanInlichting
}

export default defineComponent({
  name: 'GewestelijkRuimtelijkUitvoeringsplan',
  components: { RuimtelijkUitvoeringsplan },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const inlichtingType = TypeInlichting.GewestelijkRuimtelijkUitvoeringsplan
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)
    const vipInlichting = ref((props.inlichting ?? {}) as GewestelijkRuimtelijkUitvoeringsplanInlichting)

    return {
      vipInlichting,
      inlichtingType,
      inlichtingTitle,
      mapInlichtingToInput,
      mapInputToInlichting
    }
  }
})
