import { render, staticRenderFns } from "./gewestelijk-ruimtelijk-uitvoeringsplan.vue?vue&type=template&id=3d5b6f3c"
import script from "./gewestelijk-ruimtelijk-uitvoeringsplan.ts?vue&type=script&lang=ts&external"
export * from "./gewestelijk-ruimtelijk-uitvoeringsplan.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports