import { computed, defineComponent, PropType, ref } from 'vue'
import {
  BronInformatie,
  DossierStatus,
  GemeentelijkeVerordeningInlichting,
  GemeentelijkeVerordeningInlichtingVerordeningType,
  GemeentelijkeVerordeningInput,
  GemeentelijkeVerordeningInputVerordeningType,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator,
  InlichtingRubriek,
  Planfase,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import Verordening
  from '@/components/vip-inlichting/new-version/components/inlichtingen/overheidsplannen/verordening/verordening.vue'

function mapInlichtingToInput (vipInlichting?: GemeentelijkeVerordeningInlichting): GemeentelijkeVerordeningInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingInputInlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    referentie: vipInlichting?.referentie,
    beschrijving: vipInlichting?.beschrijving,
    planfase: vipInlichting?.planfase ?? {} as Planfase,
    externeDocumentatie: vipInlichting?.externeDocumentatie,
    verordeningType: vipInlichting?.verordeningType as unknown as GemeentelijkeVerordeningInputVerordeningType,
    discriminator: VipInlichtingDiscriminator.GEMEENTELIJKE_VERORDENING_V1,
    inlichtingType: VipInlichtingType.GEMEENTELIJKE_VERORDENING
  } as GemeentelijkeVerordeningInput
}

function mapInputToInlichting (input: GemeentelijkeVerordeningInput, inlichtingId: string): GemeentelijkeVerordeningInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.GEMEENTELIJKE_VERORDENING,
    version: 1,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.OVERHEIDSPLANNEN,
    referentie: input.referentie,
    beschrijving: input.beschrijving,
    planfase: input.planfase,
    externeDocumentatie: input.externeDocumentatie,
    verordeningType: input.verordeningType as unknown as GemeentelijkeVerordeningInlichtingVerordeningType
  } as GemeentelijkeVerordeningInlichting
}

export default defineComponent({
  name: 'GemeentelijkeVerordening',
  components: { Verordening },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const inlichtingType = TypeInlichting.GemeentelijkeVerordening
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)
    const vipInlichting = ref((props.inlichting ?? {}) as GemeentelijkeVerordeningInlichting)

    const saved = () => {
      emit('saved', vipInlichting.value)
    }

    const removed = (inlichtingId: string) => {
      emit('removed', inlichtingId)
    }

    return {
      vipInlichting,
      inlichtingType,
      inlichtingTitle,
      saved,
      removed,
      mapInlichtingToInput,
      mapInputToInlichting
    }
  }
})
