import { defineComponent, onMounted, ref } from 'vue'
import EventBus from '@/infrastructure/events/event-bus'

export default defineComponent({
  name: 'vip-inlichting-actions-remove-modal',
  props: {
    modDisabled: Boolean
  },
  setup (props, { emit }) {
    const removeModalopen = ref(false)
    const isRemoving = ref(false)
    const modalZindex = ref(402300)

    const cancel = () => {
      emit('close')
    }
    const removeInlichting = () => {
      isRemoving.value = true
      emit('remove')
    }
    onMounted(() => {
      EventBus.$on('closed-modal', cancel)
    })
    return {
      removeModalopen,
      isRemoving,
      modalZindex,
      cancel,
      removeInlichting
    }
  }
})
