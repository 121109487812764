import { render, staticRenderFns } from "./vip-inlichting-attributes-template-gemeentelijk-rooilijnplan.vue?vue&type=template&id=5a9db188"
import script from "./vip-inlichting-attributes-template-gemeentelijk-rooilijnplan.ts?vue&type=script&lang=ts&external"
export * from "./vip-inlichting-attributes-template-gemeentelijk-rooilijnplan.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports