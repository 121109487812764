export const voorschriften = [
  'Dit plan legt geen bestemming/voorschriften vast die van toepassing zijn op dit perceel',
  'Het systeem vond geen bestemming/voorschriften op dit perceel',
  'Aan te leggen afzonderlijke leidingen',
  'Aan te leggen autosnelwegen',
  'Aan te leggen hogesnelheidslijn',
  'Aan te leggen hoofdverkeerswegen',
  'Aan te leggen hoogspanningsleidingen',
  'Aan te leggen leidingstraten',
  'Aan te leggen spoorwegen',
  'Aan te leggen waterwegen',
  'Aanvullende  voorschriften gp 14 : art 1, §1 punt 1  (bruinomrand, cfr stadscentrum)',
  'Aanvullende  voorschriften gp 14 : art 1, §1 punt 2  (bruinomrand, cfr binnenstad)',
  'Aanvullende  voorschriften gp 14 : art 1, §1 punt 3  (bruinomrand, stedelijke agglomeratie)',
  'Aanvullende  voorschriften gp 14 : art 1, §1 punt 4  (bruinomrand + Romeins cijfer III)',
  'Aanvullende  voorschriften gp 14 : art 1, §1 punt 5  (bruinomrand + Romeins cijfer II)',
  'Aanvullende voorschriften gp 25 : art. 8, punt 1  (bruinomrand)',
  'Abdijgebied',
  'Agrarisch gebied met landschappelijke (of \'bijzondere\') waarde (vallei- of brongebieden)',
  'Agrarische gebieden met ecologisch belang',
  'Agrarische gebieden',
  'Alternatieve reservatiegebieden (duwvaartkanaal)',
  'Ambachtelijke bedrijven en kmo\'s',
  'Archeologische site',
  'Baarle Hertog',
  'Bedrijfsgebied met stedelijk karakter (kantoren, toonzalen, en in ondergeschikte orde woongelegenheid)',
  'Bestaande afzonderlijke leidingen',
  'Bestaande autosnelwegen',
  'Bestaande hoofdverkeerswegen',
  'Bestaande hoogspanningsleidingen',
  'Bestaande leidingstraten',
  'Bestaande luchtvaartterreinen',
  'Bestaande snelverkeerswegen',
  'Bestaande spoorwegen',
  'Bestaande waterwegen',
  'Bezinkingsgebied (afvalwater nabijgelegen fabrieken)',
  'Bijzonder groengebied (cfr paardefokkerij)',
  'Bijzonder ontginningsgebied met nabestemming natuurgebied',
  'Bijzonder reservatiegebied (cfr Teleport)',
  'Bijzonder reservatiegebied met specifieke voorwaarden',
  'Bijzondere industriegebieden (afvalverwerking)',
  'Bijzondere natuurgebieden (waterzuivering, afvoerleidingen en leidingstraten)',
  'Bosgebieden met ecologisch belang',
  'Bosgebieden',
  'Bouwvrij agrarisch gebied',
  'Brussels gewest',
  'Bufferzone met geluidswerende aarden wallen bij de economische poort internationale luchthaven Zaventem',
  'Bufferzone met geluidswerende gebouwen bij de economische poort internationale luchthaven Zaventem',
  'Bufferzones',
  'Business-park (vergader- en congresactiviteiten + overnachting, restaurant)',
  'Dienstverleningsgebieden',
  'Erfdienstbaarheids-gebieden',
  'Gebied met archeologische waarde',
  'Gebied met hoofdkwartierfunctie',
  'Gebied met toeristische waarde',
  'Gebied voor duurzame stedelijke ontwikkeling',
  'Gebied voor gemeenschaps- en openbare nutsvoorzieningen in combinatie met natuurontwikkeling',
  'Gebied voor gemeenschapsvoorzieningen en openbare nutsvoorzieningen met nabestemming natuurgebied met wetenschappelijke waarde of natuurreservaat (enkel instandhouding of gezondmaking bestaand gebouwencomplex)',
  'Gebied voor gemeenschapsvoorzieningen, openbare nutsvoorzieningen en natuurontwikkeling',
  'Gebied voor handelbeursactiviteiten en grootschalige culturele activiteiten',
  'Gebied voor inerte opvulling (inerte materialen ; sorteer- en recuperatie- installaties toegestaan)',
  'Gebied voor kernontwikkeling',
  'Gebied voor kleine niet-hinderlijke bedrijven en kantoren',
  'Gebied voor luchthavengerelateerde kantoren en diensten',
  'Gebied voor natuureducatieve infrastructuur',
  'Gebied voor openbare nutsvoorziening, bedrijventerrein en groenzone',
  'Gebied voor recreatiepark',
  'Gebied voor service-residentie',
  'Gebied voor stedelijke ontwikkeling',
  'Gebied voor uitbreiding en sanering van bestaande nijverheid',
  'Gebied voor uitbreiding van bestaande nijverheid',
  'Gebied voor wachtbekken met ondergeschikte waterrecreatieve functie',
  'Gebied voor watergebonden bedrijven',
  'Gebied voor windmolenpark',
  'Gebied voor zeehaven- en watergebonden bedrijven',
  'Gebieden hoofdzakelijk bestemd voor de vestiging van grootwinkelbedrijven',
  'Gebieden met cultureel, historische en/of esthetische waarde,  andere dan woongebieden',
  'Gebieden voor dag- én verblijf- recreatie',
  'Gebieden voor dagrecreatie',
  'Gebieden voor de vestiging van kerninstallaties',
  'Gebieden voor gemeenschapsvoorzieningen en openbaar nut',
  'Gebieden voor jeugdcamping',
  'Gebieden voor toeristische recreatieparken (TRP)',
  'Gebieden voor verblijfrecreatie',
  'Gebieden voor wachtbekken',
  'Gecontroleerd overstromingsgebied',
  'Gemengd gemeenschapsvoorzienings- en dienstverleningsgebied (+ inrichtingen ivm haven en scheepvaart)',
  'Gemengd woon- en parkgebied',
  'Gemengde woon- en industriegebieden',
  'Golfterrein',
  'Groengebied met vissershutten',
  'Groengebieden met semi-residentiele functie',
  'Groengebieden',
  'Havenuitbreidingsgebied',
  'Industrie-stortgebied (niet-giftige industriële afval)',
  'Industriegebied met bijzondere bestemming (testen van autovoertuigen)',
  'Industriegebied met nabestemming natuurgebied (bestaande bedrijven ; geen uitbreiding mogelijk)',
  'Industriegebied met nabestemming woongebied',
  'Industriegebied voor milieubelastende industrie met nabestemming groengebied (breekwerf- en betoncentrale)',
  'Industriegebieden',
  'Kantoor en dienstenzone',
  'Kleinhandelszone',
  'Kleintuingebied',
  'Kleiontginnings gebieden',
  'Koppelingsgebied K 2/type 2',
  'Koppelingsgebied K1/type 1',
  'Koppelingsgebied type 1 (gewestplan Sint-Niklaas-Lokeren)',
  'Laguneringsveld met nabestemming bosgebied',
  'Landbouwgebied met culturele, historische en/of esthetische waarde',
  'Landelijke gebieden',
  'Landschappelijk waardevolle agrarische gebieden',
  'Landschappelijk waardevolle gebieden, andere dan agrarische gebieden',
  'Lokaal bedrijventerrein met openbaar karakter',
  'Luchthaven gebonden bedrijventerrein',
  'Milieubelastende industrieën',
  'Militaire gebieden',
  'Multimodale logistieke zone in zeehavengebied type 2',
  'Museumcentrum (in natuurgebied)',
  'Natuureducatieve infrastructuur',
  'Natuurgebied met bijzondere voorschriften voor de kleinijverheid',
  'Natuurgebied met erfdienstbaarheid (t.a.v. transport- en pijpleidingen)',
  'Natuurgebied met tijdelijke nevenfunctie waterwinning',
  'Natuurgebieden met wetenschappelijke waarde of natuurreservaten',
  'Natuurgebieden',
  'Nog onbestemd in 1994',
  'Oeverstrook met bijzondere bestemming (Antwerpse kaaien)',
  'Omhullende polygoon',
  'Ontginningsgebied met nabestemming natuurontwikkeling',
  'Ontginningsgebied met nabestemming recreatie en natuur',
  'Ontginningsgebieden',
  'Opspuitings- en ontginningsgebieden',
  'Opspuitingsgebied (verbreding Albertkanaal)',
  'Overstromingsgebieden',
  'Parkgebied voor verzorgings- instellingen',
  'Parkgebieden met semi-agrarische functie',
  'Parkgebieden',
  'Pleisterplaats voor nomaden of woonwagenbewoners',
  'Projectgebied ter uitvoering van de maatregelen tegen grondlawaai bij de economische poort internationale luchthaven Zaventem',
  'Publieke bedrijvenzones',
  'Recreatiegebied met nabestemming natuur',
  'Recreatiegebieden',
  'Recreatiepark',
  'Recreatieve parkgebieden',
  'Regionaal bedrijventerrein met openbaar karakter',
  'Regionaal bedrijventerrein',
  'Regionale gemengde zone voor diensten en handel',
  'Researchpark',
  'Reservatie-gebieden',
  'Reservatiestrook met leefbaarheidsbuffer type 2',
  'Reservatiestrook met leefbaarheidsbuffer',
  'Reservatiestrook voor buffer',
  'Reservegebied voor bufferzone',
  'Reservegebied voor gemeenschapsvoorzieningen en openbare nutsvoorzieningen',
  'Reservegebied voor regionaal bedrijventerrein met openbaar karakter',
  'Reservegebied voor sliblagunering',
  'Reservegebieden voor (dag-) recreatie',
  'Reservegebieden voor ambachtelijke bedrijven en kmo\'s',
  'Reservegebieden voor ambachtelijke uitbreiding',
  'Reservegebieden voor beperkte industriele uitbreiding (gp 05 en 06 : \'industriële of ambachtelijke bedrijven\' / gp 07 : \'bedrijven\'  ... die op de onmiddellijk aanpalende terreinen gevestigd zijn)',
  'Reservegebieden voor industriele uitbreiding (industrieel + ambachtelijk)',
  'Reservegebieden voor industriele uitbreiding (industrieel + uitbreiding havenactiviteiten)',
  'Reservegebieden voor kleiontginning',
  'Reservegebieden voor ontginning',
  'Reservegebieden voor recreatie',
  'Reservegebieden voor woonwijken',
  'Restgebiedjes',
  'Serregebieden',
  'Speelbossen of speelweiden',
  'Stortgebied met nabestemming natuurontwikkeling (niet-giftige baggerspecie)',
  'Stortgebieden (huisafval en niet-giftige stoffen)',
  'Stortgebieden (niet-giftige stoffen)',
  'Stortgebieden voor gepollueerde gronden (met zware metalen vervuilde grond)',
  'Teleport (hoogwaardig kantorenpark met geavanceerde telecommunicatievoorzieningen)',
  'Tijdelijk gebied voor gemeenschapsvoorzieningen (autokeuring)',
  'Tijdelijk ontginningsgebied (tot 31/12/1981)',
  'Tijdelijk ontginningsgebied met nabestemming bosgebied (bosgebied na opvulling zandgroeve uiterlijk tegen 1/6/98)',
  'Transportzone',
  'Uitbreiding van ontginningsgebied in een waterwinningsgebied',
  'Uitbreiding van ontginningsgebied met nabestemming natuurgebied',
  'Uitbreidingen van ontginningsgebieden',
  'Uitbreidingsgbied voor stedelijke functies',
  'Uitbreidingsgebied voor bos (bosuitbreidingsgebied)',
  'Uitbreidingsgebied voor recreatie',
  'Universiteitspark',
  'Valleigebieden (of \'agrarische gebieden met landschappelijke waarde\')',
  'Vliegveld / recreatie-gebied (gp Turnhout)',
  'Vliegveld van Deurne',
  'Waterwinningsgebieden',
  'Wetenschapspark',
  'Woonaansnijdingsgebied',
  'Woongebied met recreatief karakter',
  'Woongebieden',
  'Woongebieden met cultureel, historische en/of esthetische waarde',
  'Woongebieden met landelijk karakter en cultureel, historische en/of esthetische waarde',
  'Woongebieden met landelijk karakter',
  'Woonpark',
  'Woonreservegebieden',
  'Woonuitbreidingsgebieden',
  'Zeehavengebied met tijdelijke agrarische bestemming',
  'Zeehavengebied met tijdelijke bestemming valleigebied',
  'Zeehavengebied type 2',
  'Zeehavengebied type 3',
  'Zone met cultuurhistorische waarde',
  'Zone met veiligheidsoverdruk',
  'Zone voor gemeenschaps- en openbare nutsvoorzieningen met nabestemming natuurgebied',
  'Zone voor handelsvestigingen',
  'Zone voor jachthavenontwikkeling',
  'Zone voor kleinhandel en kleine en middelgrote ondernemingen',
  'Zone voor Koninklijk Domein',
  'Zone voor natuurontwikkeling',
  'Zone voor openbaar nut met nabestemming bosgebied',
  'Zone voor opslagplaatsen (bouwmaterialen zuidelijk gelegen ambachtelijke bedrijf)',
  'Zone voor renovatie (ZR)'
]
