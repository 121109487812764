import { render, staticRenderFns } from "./stedenbouwkundige-overtreding-edit.vue?vue&type=template&id=235d63ca"
import script from "./stedenbouwkundige-overtreding-edit.ts?vue&type=script&lang=ts&external"
export * from "./stedenbouwkundige-overtreding-edit.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports