import { computed, defineComponent, PropType } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  BeroepsprocedureInstantie,
  BeroepsprocedureRaadVoorVergunningsbetwistingen,
  BeroepsprocedureRaadVoorVergunningsbetwistingenBeslissing,
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator,
  InlichtingRubriek,
  TypeInlichting,
  VergundGeachteGebouwenBeroepsprocedure,
  VergundGeachteGebouwenInlichting,
  VergundGeachteGebouwenInlichtingFunctieGebouw,
  VergundGeachteGebouwenInlichtingStatus,
  VergundGeachteGebouwenInlichtingType,
  VergundGeachteGebouwenInput,
  VergundGeachteGebouwenInputFunctieGebouw,
  VergundGeachteGebouwenInputStatus,
  VergundGeachteGebouwenInputType,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'

function mapInlichtingToInput (vipInlichting?: VergundGeachteGebouwenInlichting): VergundGeachteGebouwenInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingInputInlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.VERGUND_GEACHTE_GEBOUWEN_V1,
    inlichtingType: VipInlichtingType.VERGUND_GEACHTE_GEBOUWEN,
    beschrijving: vipInlichting?.beschrijving,
    referentie: vipInlichting?.referentie,
    gemeentelijkDossiernummer: vipInlichting?.gemeentelijkDossiernummer,
    type: vipInlichting?.type as unknown as VergundGeachteGebouwenInputType,
    functieGebouw: vipInlichting?.functieGebouw as unknown as VergundGeachteGebouwenInputFunctieGebouw,
    kwetsbaarGebiedIndicator: vipInlichting?.kwetsbaarGebiedIndicator,
    status: vipInlichting?.status as unknown as VergundGeachteGebouwenInputStatus,
    datumBeslissing: vipInlichting?.datumBeslissing,
    beroepsprocedure: vipInlichting?.beroepsprocedure ?? {} as VergundGeachteGebouwenBeroepsprocedure,
    externeDocumentatie: vipInlichting?.externeDocumentatie
  } as VergundGeachteGebouwenInput
}

function mapInputToInlichting (input: VergundGeachteGebouwenInput, inlichtingId: string): VergundGeachteGebouwenInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.SPLITSING,
    version: 1,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.OVERHEIDSPLANNEN,
    beschrijving: input.beschrijving,
    referentie: input.referentie,
    gemeentelijkDossiernummer: input.gemeentelijkDossiernummer,
    type: input.type as unknown as VergundGeachteGebouwenInlichtingType,
    functieGebouw: input.functieGebouw as unknown as VergundGeachteGebouwenInlichtingFunctieGebouw,
    kwetsbaarGebiedIndicator: input.kwetsbaarGebiedIndicator,
    status: input.status as unknown as VergundGeachteGebouwenInlichtingStatus,
    datumBeslissing: input.datumBeslissing,
    beroepsprocedure: {
      instantie: BeroepsprocedureInstantie.RAAD_VOOR_VERGUNNINGSBETWISTINGEN,
      beslissing: input.beroepsprocedure.beslissing as unknown as BeroepsprocedureRaadVoorVergunningsbetwistingenBeslissing,
      datum: input.beroepsprocedure.datum,
    } as BeroepsprocedureRaadVoorVergunningsbetwistingen,
    externeDocumentatie: input.externeDocumentatie
  } as VergundGeachteGebouwenInlichting
}

export default defineComponent({
  name: 'VergundGeachteGebouwen',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew, VipInlichtingEdit },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted
    } = useEditableInlichting(
      props.inlichting ?? ({ inlichtingType: VipInlichtingType.VERGUND_GEACHTE_GEBOUWEN } as unknown as VergundGeachteGebouwenInlichting),
      mapInlichtingToInput,
      mapInputToInlichting)

    const inlichtingType = TypeInlichting.VergundGeachteGebouwen
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)
    const beroepAangetekend = computed({
      get () {
        return inlichtingInput.value.beroepsprocedure != null
      },
      set (value) {
        inlichtingInput.value.beroepsprocedure = value ? {} as VergundGeachteGebouwenBeroepsprocedure : null
      },
    })

    const procedureClasses = computed(() => 'vip-box vip-box--small' + (beroepAangetekend.value ? ' vip-box-no-bottom' : ''))

    const optionsStatus = new Map([
      ['POSITIEF', 'Positief'],
      ['NEGATIEF', 'Negatief'],
      ['LOPENDE', 'Lopende']
    ])

    const optionsType = new Map([
      ['WEERLEGBAAR', 'Weerlegbaar'],
      ['ONWEERLEGBAAR', 'Onweerlegbaar'],
    ])

    const optionsFunctie = new Map([
      ['ANDER', 'Andere'],
      ['DAGRECREATIE', 'Dagrecreatie'],
      ['HANDEL_HORECA_EN_DIENSTEN', 'Handel, horeca en diensten'],
      ['INDUSTRIE_EN_AMBACHT', 'Industrie en ambacht'],
      ['LAND_EN_TUINBOUW', 'Land- en tuinbouw'],
      ['OPENBAAR_NUT', 'Gemeenschapsvoorziening of openbaar nut'],
      ['VERBLIJFSRECREATIE', 'Verblijfsrecreatie'],
      ['WONEN', 'Wonen'],
    ])

    const optionsBeslissing = new Map([
      ['DEELS_VERGUND', 'Deels vergund'],
      ['GESCHORST', 'Geschorst'],
      ['LOPENDE', 'Lopende'],
      ['ONONTVANKELIJK', 'Onontvankelijk'],
      ['NIET_GESCHORST', 'Niet geschorst'],
      ['NIET_VERNIETIGD', 'Niet vernietigd'],
      ['VERNIETIGD', 'Vernietigd'],
    ])

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.status) {
        errors.set('status', 'Status is verplicht')
      }
      if (!inlichtingInput.value.beroepsprocedure.beslissing) {
        errors.set('beroep-status', 'Status is verplicht')
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      optionsBeslissing,
      optionsType,
      optionsFunctie,
      optionsStatus,
      beroepAangetekend,
      procedureClasses,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      formatNumber,
      formatDate
    }
  }
})
