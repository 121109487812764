import { computed, defineComponent, PropType, ref } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  DossierStatus,
  Inlichting,
  SoortWegWaarlangsPerceelGelegenIsInlichting,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import VipInlichtingAccordion
  from '@/components/vip-inlichting/components/vip-inlichting-accordion/vip-inlichting-accordion.vue'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'

export default defineComponent({
  name: 'SoortWegV1',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props) {
    const title = ref(null)
    const vipInlichting = ref(props.inlichting as SoortWegWaarlangsPerceelGelegenIsInlichting)
    const accordion = ref<InstanceType<typeof VipInlichtingAccordion>>(null)
    const inlichtingType = TypeInlichting.SoortWegWaarlangsPerceelGelegenIs
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const options = new Map([
      ['GEMEENTE', 'Gemeente'],
      ['AGENTSCHAP_WEGEN_EN_VERKEER', 'Agentschap Wegen en Verkeer'],
      ['VLAAMSE_WATERWEG', 'Vlaamse Waterweg'],
      ['AGENTSCHAP_MARITIEME_DIENSTVERLENING_EN_KUST', 'Agentschap Maritieme Dienstverlening en Kust'],
      ['OOSTKUSTPOLDER', 'Oostkustpolder'],
      ['NIEUWE_POLDER_VAN_BLANKENBERGE', 'Nieuwe polder van Blankenberge'],
      ['DIENST_WATERLOPEN_VAN_DE_PROVINCIE', 'Dienst Waterlopen van de Provincie'],
      ['AFDELING_MARITIEME_TOEGANG', 'Afdeling Maritieme Toegang'],
      ['PORT_OF_ANTWERP_BRUGES', 'Port of Antwerp-Bruges'],
      ['INFRABEL', 'Infrabel'],
      ['VLAAMSE_MILIEUMAATSCHAPPIJ', 'Vlaamse Milieumaatschappij'],
      ['VLAAMS_MINISTERIE_VAN_MOBILITEIT_EN_OPENBARE_WERKEN_MARITIEME_TOEGANG', 'Vlaams Ministerie van Mobiliteit en Openbare Werken - Maritieme Toegang'],
      ['VLAAMS_MINISTERIE_VAN_MOBILITEIT_EN_OPENBARE_WERKEN_AGENTSCHAP_VOOR_MARITIEME_DIENSTVERLENING_EN_KUST_ADMINISTRATIE_WATERWEGEN_EN_ZEEWEZEN_AFDELING_KUST', 'Vlaams Ministerie van Mobiliteit en Openbare Werken - Agentschap voor Maritieme dienstverlening en kust - Administratie waterwegen en zeewezen - Afdeling kust'],
      ['VLAAMS_MINISTERIE_VAN_MOBILITEIT_EN_OPENBARE_WERKEN_AGENTSCHAP_INFRASTRUCTUUR_AFDELING_WEGEN_EN_VERKEER_WEST_VLAANDEREN', 'Vlaams Ministerie van Mobiliteit en Openbare Werken - Agentschap Infrastructuur - Afdeling wegen en verkeer West-Vlaanderen'],
    ])

    return {
      title,
      inlichtingType,
      vipInlichting,
      accordion,
      inlichtingTitle,
      options
    }
  }
})
