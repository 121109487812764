import {
  Document,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInput, InlichtingInputInlichtingenIndicator
} from '@/infrastructure/bff-client/bff-client'
import { computed, getCurrentInstance, onMounted, reactive, Ref, ref } from 'vue'
import { Aangevraagd } from '@/infrastructure/constants/dashboard-tabs'
import { useUserStore } from '@/infrastructure/stores/user-store'
import { useDossierStore } from '@/infrastructure/stores/dossier-store'
import VipInlichtingAccordion
  from '@/components/vip-inlichting/components/vip-inlichting-accordion/vip-inlichting-accordion'
import { generateKey } from '@/infrastructure/helpers/general'
import { BijlageUploadResult } from '@/components/vip-inlichting/vip-inlichting-bijlagen-service'

export default function useEditableInlichting (
  inlichting: Inlichting,
  mapInlichtingToInput: (vipInlichting?: Inlichting)=> InlichtingInput,
  mapInputToInlichting: (input: InlichtingInput, inlichtingId: string)=> Inlichting) {
  const refs: { [key: string]: Ref } = reactive({})
  const vipInlichting = ref(inlichting)
  const bijlagen = ref(inlichting.bijlagen)
  const inlichtingInput = ref(mapInlichtingToInput(inlichting))
  const addedBijlagen = ref([] as File[])
  const saving = ref(false)

  const validationErrors = ref(new Map<string, string>())
  const accordion = ref<InstanceType<typeof VipInlichtingAccordion>>(null)

  const route = getCurrentInstance()?.proxy?.$route

  const userStore = useUserStore()
  const dossierStore = useDossierStore()

  const isDossierEditable = computed(() => !dossierStore.dossierIsReadOnly && route?.params?.dossierType !== Aangevraagd)
  const canEdit = computed(() => isDossierEditable.value && (userStore.userCanEditDossier || userStore.userIsAdmin))
  const isNietGekend = computed(() => vipInlichting.value.inlichtingenIndicator === InlichtingenIndicator.NIET_GEKEND)

  const generateInlichtingKey = () => `${(inlichting as any).inlichtingType?.toString().toLowerCase()}-${generateKey()}`
  const inlichtingKey = ref(generateInlichtingKey())

  const bijlageUploaded = (bijlagen: File[]) => {
    addedBijlagen.value.push(...bijlagen)
  }

  const downloadUrl = (bijlageId: string): string => {
    return config.VUE_APP_BASE_URI + '/bff/dossiers/bijlagen/' + bijlageId
  }

  const inlichtingToggled = (isOpen: boolean) => {
    if (isOpen) {
      if (vipInlichting.value.inlichtingenIndicator === InlichtingenIndicator.JA) {
        const element = refs[inlichting.inlichtingId].value
        scrollInlichtingIntoView(element)
      } else {
        window.scrollBy({ top: 200 })
      }
    }
  }

  const toggleIndicator = (vanToepassing: boolean) => {
    inlichtingInput.value.inlichtingenIndicator = vanToepassing ? InlichtingInputInlichtingenIndicator.JA : InlichtingInputInlichtingenIndicator.NEE
  }

  const cancelEdit = () => {
    inlichtingInput.value = mapInlichtingToInput(vipInlichting.value)
    accordion.value.opened = false
    scrollInlichtingIntoView(accordion.value)
    validationErrors.value.clear()
  }

  const removeInlichting = () => {
    accordion.value.opened = false
  }

  const saveInlichting = (inlichtingId: string) => {
    vipInlichting.value = mapInputToInlichting(inlichtingInput.value, inlichtingId)
    vipInlichting.value.bijlagen = bijlagen.value
    inlichtingKey.value = generateInlichtingKey()
    accordion.value.opened = false
    scrollInlichtingIntoView(accordion.value)
    validationErrors.value.clear()
  }

  const bijlagenSaved = (bijlageUploadedResult?: BijlageUploadResult[]) => {
    if (addedBijlagen.value.length > 0) {
      const mappedBijlagen = addedBijlagen.value.map(bijlage => {
        return {
          bijlageId: bijlageUploadedResult.find(res => res.bijlageName === bijlage.name).bijlageId,
          bestandsgrootte: bijlage.size,
          mimeType: bijlage.type,
          origineleBestandsnaam: bijlage.name
        } as Document
      })
      bijlagen.value.push(...mappedBijlagen)
      inlichtingKey.value = generateInlichtingKey()
    }
  }

  const bijlageDeleted = (bijlageId: string) => {
    const bijlageIndex = bijlagen.value.findIndex(bijlage => bijlage.bijlageId === bijlageId)
    if (bijlageIndex > -1) {
      vipInlichting.value.bijlagen.splice(bijlageIndex, 1)
      bijlagen.value.splice(bijlageIndex, 1)
      inlichtingKey.value = generateInlichtingKey()
    }
  }

  const scrollInlichtingIntoView = (element: any) => {
    if (element) {
      setTimeout(() => {
        element.$el.scrollIntoView({ behavior: 'smooth' })
        window.scrollBy({ top: -160 })
      }, 100)
    }
  }

  const validationFailed = (errors: Map<string, string>) => {
    validationErrors.value = errors
  }

  onMounted(() => {
    if (!inlichting) { return }
    refs[inlichting.inlichtingId] = ref(null)
  })

  return {
    refs,
    accordion,
    vipInlichting,
    inlichtingInput,
    addedBijlagen,
    validationErrors,
    saving,
    inlichtingKey,
    canEdit,
    isNietGekend,
    bijlageDeleted,
    validationFailed,
    bijlageUploaded,
    toggleIndicator,
    inlichtingToggled,
    cancelEdit,
    saveInlichting,
    removeInlichting,
    downloadUrl,
    bijlagenSaved
  }
}
