import { computed, defineComponent, PropType } from 'vue'
import {
  AankoopOfGebruikGemeentegrondInlichting,
  AankoopOfGebruikGemeentegrondInlichtingType,
  AankoopOfGebruikGemeentegrondInput,
  AankoopOfGebruikGemeentegrondInputType,
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator,
  InlichtingRubriek, TypeInlichting, VipInlichtingDiscriminator, VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import { formatNumber } from '@/infrastructure/filters/filters'
import EventBus from '@/infrastructure/events/event-bus'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'

function mapInlichtingToInput (vipInlichting?: AankoopOfGebruikGemeentegrondInlichting): AankoopOfGebruikGemeentegrondInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingInputInlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    beschrijving: vipInlichting?.beschrijving,
    type: vipInlichting?.type as unknown as AankoopOfGebruikGemeentegrondInputType,
    externeDocumentatie: vipInlichting?.externeDocumentatie,
    discriminator: VipInlichtingDiscriminator.AANKOOP_OF_GEBRUIK_GEMEENTEGROND_V1,
    inlichtingType: VipInlichtingType.AANKOOP_OF_GEBRUIK_GEMEENTEGROND,
    bijlagen: vipInlichting?.bijlagen,
  } as AankoopOfGebruikGemeentegrondInput
}

function mapInputToInlichting (input: AankoopOfGebruikGemeentegrondInput, inlichtingId: string): AankoopOfGebruikGemeentegrondInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.AANKOOP_OF_GEBRUIK_GEMEENTEGROND,
    externeDocumentatie: input.externeDocumentatie,
    bijlagen: input.bijlagen,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.GROND_EN_PANDENBELEID,
    beschrijving: input.beschrijving,
    type: input.type as unknown as AankoopOfGebruikGemeentegrondInlichtingType
  } as AankoopOfGebruikGemeentegrondInlichting
}

export default defineComponent({
  name: 'AankoopOfGebruikGemeentegrond',
  components: { VipInlichtingVanToepassing },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      inlichtingKey,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      bijlagenSaved,
      bijlageDeleted,
      downloadUrl
    } = useEditableInlichting(
      props.inlichting ?? ({ inlichtingType: VipInlichtingType.AANKOOP_OF_GEBRUIK_GEMEENTEGROND } as unknown as AankoopOfGebruikGemeentegrondInlichting),
      mapInlichtingToInput,
      mapInputToInlichting
    )

    const inlichtingType = TypeInlichting.AankoopOfGebruikGemeentegrond
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const typeOptions =
        new Map<string, string>([
          ['AANKOOP', 'Aankoop'],
          ['GEBRUIK', 'Gebruik']
        ])

    const getTypeLabel = (type: string): string => {
      if (typeOptions.has(type)) {
        return typeOptions.get(type)
      }
    }

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.beschrijving) {
        errors.set('beschrijving', 'Beschrijving is verplicht')
      }
      return errors
    }

    const validationFailed = (errors: Map<string, string>) => {
      validationErrors.value = errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      typeOptions,
      getTypeLabel,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      formatNumber
    }
  }
})
