import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import ProceduresEdit from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/edit/procedure/procedures-edit.vue'
import useStedenbouwkundigeOvertredingLabels
  from '@/components/vip-inlichting/new-version/components/inlichtingen/vergunningen/stedenbouwkundige-overtreding/composable/stedenbouwkundige-overtreding-labels.composable'
import { Document, Overtreding, StedenbouwkundigeOvertredingInput } from '@/infrastructure/bff-client/bff-client'

export default defineComponent({
  name: 'stedenbouwkundige-overtreding-edit',
  components: {
    ProceduresEdit
  },
  props: {
    input: Object as PropType<StedenbouwkundigeOvertredingInput>,
    bijlagen: Array as PropType<Document[]>,
    inlichtingId: String,
    dossierId: String,
    inlichtingKey: String,
    validationErrors: Map<string, string>,
    saving: Boolean
  },
  setup (props, { emit }) {
    const { options, getLabel } = useStedenbouwkundigeOvertredingLabels()

    const gerelateerdeWetgevingOption1 = computed(() => Array.from(options.get('gerelateerdeWetgeving').keys())[0])
    const gerelateerdeWetgevingOption2 = computed(() => Array.from(options.get('gerelateerdeWetgeving').keys())[1])
    const inlichtingInput = ref(props.input)
    const overtreding = ref(props.input.overtreding ?? {} as Overtreding)

    const gerelateerdeWetgevingLabel = (key: string) => options.get('gerelateerdeWetgeving').get(key)

    const bijlageUploaded = (bijlagen: File[]) => {
      emit('bijlage-uploaded', bijlagen)
    }

    const proceduresUpdated = (input: StedenbouwkundigeOvertredingInput) => {
      inlichtingInput.value = input
    }

    const handleOvertreding = () => {
      if (overtreding.value.status != null && overtreding.value.toelichting == null && overtreding.value.datum == null) {
        inlichtingInput.value.overtreding = null
        return
      }
      inlichtingInput.value.overtreding = overtreding.value
    }

    const bijlageDeleted = (bijlageId: string) => {
      emit('bijlage-deleted', bijlageId)
    }

    watch(inlichtingInput, (newValue) => emit('input-updated', newValue), { deep: true })

    return {
      inlichtingInput,
      overtreding,
      options,
      gerelateerdeWetgevingOption1,
      gerelateerdeWetgevingOption2,
      formatNumber,
      getLabel,
      formatDate,
      gerelateerdeWetgevingLabel,
      bijlageUploaded,
      proceduresUpdated,
      handleOvertreding,
      bijlageDeleted
    }
  }
})
