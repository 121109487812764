import EventBus from '@/infrastructure/events/event-bus'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'inlichting-actions',
  props: {
    modDisabled: Boolean,
    modSaving: Boolean,
    submitButtonLabel: {
      default: 'Bewaren',
      type: String
    },
    removing: {
      type: Boolean,
      required: false,
      default: false
    },
    canDelete: Boolean,
    invalid: Boolean
  },
  setup (props, { emit }) {
    const removeModalopen = ref(false)
    const isRemoving = ref(props.removing)
    const length = ref(0)

    const save = () => {
      emit('save')
    }

    const cancel = () => {
      emit('cancelled')
      removeModalopen.value = false
    }

    const remove = () => {
      removeModalopen.value = true
    }

    const removeInlichting = () => {
      isRemoving.value = true
      emit('remove')
    }

    watch(() => props.removing, (value) => {
      if (value === false && removeModalopen.value) {
        removeModalopen.value = false
        isRemoving.value = false
        EventBus.$emit('close-modal', 'vl-modal-backdrop')
      }
    }, { immediate: true })

    return {
      removeModalopen,
      isRemoving,
      length,
      save,
      cancel,
      remove,
      removeInlichting
    }
  }
})
