import { computed, defineComponent, PropType } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator,
  InlichtingRubriek,
  LeegstaandeEnVerwaarloosdeBedrijfsruimtenInlichting,
  LeegstaandeEnVerwaarloosdeBedrijfsruimtenInput,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'

function mapInlichtingToInput (vipInlichting?: LeegstaandeEnVerwaarloosdeBedrijfsruimtenInlichting): LeegstaandeEnVerwaarloosdeBedrijfsruimtenInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingInputInlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.LEEGSTAANDE_EN_VERWAARLOOSDE_BEDRIJFSRUIMTEN_V1,
    inlichtingType: VipInlichtingType.LEEGSTAANDE_EN_VERWAARLOOSDE_BEDRIJFSRUIMTEN,
    opgenomenSinds: vipInlichting?.opgenomenSinds
  } as LeegstaandeEnVerwaarloosdeBedrijfsruimtenInput
}

function mapInputToInlichting (input: LeegstaandeEnVerwaarloosdeBedrijfsruimtenInput, inlichtingId: string): LeegstaandeEnVerwaarloosdeBedrijfsruimtenInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.LEEGSTAANDE_EN_VERWAARLOOSDE_BEDRIJFSRUIMTEN,
    version: 1,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.OVERHEIDSPLANNEN,
    beschrijving: input.beschrijving
  } as LeegstaandeEnVerwaarloosdeBedrijfsruimtenInlichting
}

export default defineComponent({
  name: 'Vergunningstoestand',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew, VipInlichtingEdit },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted
    } = useEditableInlichting(
      props.inlichting ?? ({
        inlichtingType: VipInlichtingType.LEEGSTAANDE_EN_VERWAARLOOSDE_BEDRIJFSRUIMTEN,
        version: 1
      } as unknown as LeegstaandeEnVerwaarloosdeBedrijfsruimtenInlichting),
      mapInlichtingToInput,
      mapInputToInlichting)

    const inlichtingType = TypeInlichting.LeegstaandeEnVerwaarloosdeBedrijfsruimten
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.beschrijving) {
        errors.set('opgenomen', 'Opgenomen sinds is verplicht')
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      formatNumber,
      formatDate
    }
  }
})
