import { nextTick, ref, defineComponent, PropType } from 'vue'
import { Document, InlichtingAttribuut } from '@/infrastructure/bff-client/bff-client'
import { BffClientFactory } from '@/infrastructure/bff-client/bff-client-handler'
import { formatNumber } from '../../infrastructure/filters/filters'

export default defineComponent({
  name: 'vip-bijlage-upload',
  emits: ['bijlagen-to-upload-changed', 'deleted'],
  props: {
    dossierId: {
      type: String,
      required: true
    },
    inlichtingId: {
      type: String,
      required: false
    },
    bijlagen: {
      type: Object as PropType<InlichtingAttribuut>,
      required: false
    },
    vipBijlagen: {
      type: Array as PropType<Document[]>,
      required: false
    }
  },
  setup (props, { emit }) {
    const dropzoneOptions = ref({
      autoProcessQueue: false,
      uploadMultiple: true,
      parallelUploads: 20,
      maxFilesize: 10,
      acceptedFiles: '.jpg, .jpeg", .jpe, .png, .tiff, .pdf',
      dictFileTooBig: 'Bestand is te groot. Max {{maxFilesize}} MB toegestaan.'
    })
    const bijlageLijst = ref<Document[]>(
      props.vipBijlagen
        ? props.vipBijlagen
        : ((props.bijlagen as InlichtingAttribuut)?.value as Document[])
    )
    const bijlagenToUpload = ref(null)

    const processFileAdded = () => {
      const bijlagenToUploadRef = bijlagenToUpload.value
      if (bijlagenToUploadRef) {
        nextTick(() => {
          const acceptedFiles = (bijlagenToUploadRef.$refs.dropzoneRef as any).dropzone.getAcceptedFiles()
          emit('bijlagen-to-upload-changed', [...acceptedFiles])
        })
      }
    }

    const downloadUrl = (bijlageId: string): string => {
      return `${config.VUE_APP_BASE_URI}/bff/dossiers/bijlagen/${bijlageId}`
    }

    const deleteBijlage = (bijlageId: string) => {
      BffClientFactory().dossierInlichtingenBijlagen_Delete(props.dossierId, props.inlichtingId, bijlageId).then(() => {
        const bijlageIndex = bijlageLijst.value.findIndex(bijlage => bijlage.bijlageId === bijlageId)
        if (bijlageIndex > -1) {
          bijlageLijst.value.splice(bijlageIndex, 1)
        }
        emit('deleted', bijlageId)
      })
    }

    return {
      bijlagenToUpload,
      dropzoneOptions,
      bijlageLijst,
      processFileAdded,
      downloadUrl,
      deleteBijlage,
      formatNumber
    }
  }
})
