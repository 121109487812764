import { render, staticRenderFns } from "./gemeentelijk-ruimtelijk-uitvoeringsplan.vue?vue&type=template&id=2201a574"
import script from "./gemeentelijk-ruimtelijk-uitvoeringsplan.ts?vue&type=script&lang=ts&external"
export * from "./gemeentelijk-ruimtelijk-uitvoeringsplan.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports