import { computed, defineComponent, onBeforeMount, PropType, ref, watch } from 'vue'
import {
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator,
  InlichtingRubriek,
  OnbebouwdePercelenInlichting, OnbebouwdePercelenInlichtingCategorie,
  OnbebouwdePercelenInput,
  OnbebouwdePercelenInputCategorie,
  TypeInlichting, VipInlichtingDiscriminator, VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import EventBus from '@/infrastructure/events/event-bus'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'

function mapInlichtingToInput (vipInlichting?: OnbebouwdePercelenInlichting): OnbebouwdePercelenInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingInputInlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    beschrijving: vipInlichting?.beschrijving,
    referentie: vipInlichting?.referentie,
    categorie: vipInlichting?.categorie as unknown as OnbebouwdePercelenInputCategorie,
    lotnummer: vipInlichting?.lotnummer,
    externeDocumentatie: vipInlichting?.externeDocumentatie,
    discriminator: VipInlichtingDiscriminator.ONBEBOUWDE_PERCELEN_V1,
    inlichtingType: VipInlichtingType.ONBEBOUWDE_PERCELEN,
    bijlagen: vipInlichting?.bijlagen,
  } as OnbebouwdePercelenInput
}

function mapInputToInlichting (input: OnbebouwdePercelenInput, inlichtingId: string): OnbebouwdePercelenInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.ONBEBOUWDE_PERCELEN,
    externeDocumentatie: input.externeDocumentatie,
    bijlagen: input.bijlagen,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.GROND_EN_PANDENBELEID,
    beschrijving: input.beschrijving,
    referentie: input.referentie,
    lotnummer: input.lotnummer,
    categorie: input.categorie as unknown as OnbebouwdePercelenInlichtingCategorie
  } as OnbebouwdePercelenInlichting
}

export default defineComponent({
  name: 'OnbebouwdePercelen',
  components: { VipInlichtingVanToepassing },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      bijlagenSaved,
      bijlageDeleted,
      downloadUrl
    } = useEditableInlichting(
      props.inlichting ??
        ({ inlichtingType: VipInlichtingType.ONBEBOUWDE_PERCELEN } as unknown as OnbebouwdePercelenInlichting),
      mapInlichtingToInput,
      mapInputToInlichting
    )

    const inlichtingType = TypeInlichting.OnbebouwdePercelen
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)
    const previewTitle = computed(() => {
      if (vipInlichting.value.categorie === OnbebouwdePercelenInlichtingCategorie.ONBEBOUWDE_BOUWGROND) {
        return 'Onbebouwde bouwgrond'
      }
      if (vipInlichting.value.categorie === OnbebouwdePercelenInlichtingCategorie.ONBEBOUWDE_KAVEL) {
        return 'Onbebouwde kavel'
      }
      return inlichtingTitle
    })

    const lotenInput = ref([] as string[])

    const options = new Map([
      ['ONBEBOUWDE_BOUWGROND', 'Bouwgrond'],
      ['ONBEBOUWDE_KAVEL', 'Kavel'],
    ])

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!!inlichtingInput.value.beschrijving === false) {
        errors.set('beschrijving', 'Beschrijving is verplicht')
      }
      if (!!inlichtingInput.value.referentie === false) {
        errors.set('referentie', 'Referentie is verplicht')
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    watch(() => lotenInput.value, (newValue) => {
      if (!!newValue === false) { return }
      inlichtingInput.value.lotnummer = newValue.join(', ')
    })

    onBeforeMount(() => {
      lotenInput.value = vipInlichting.value.lotnummer?.split(',').map((str: string) => str.trim())
    })

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      previewTitle,
      options,
      lotenInput,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      formatNumber,
      formatDate
    }
  }
})
