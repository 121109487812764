import { computed, defineComponent, PropType, watch } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  DossierStatus,
  Inlichting,
  InlichtingInput,
  InlichtingInputInlichtingenIndicator, Planfase, PlanfaseStatus,
  TypeInlichting
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'
import { voorschriften } from '@/components/vip-inlichting/new-version/components/inlichtingen/overheidsplannen/voorschriften-options'

export default defineComponent({
  name: 'RuimtelijkUitvoeringsplan',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew, VipInlichtingEdit },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    inlichtingType: Number as PropType<TypeInlichting>,
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean,
    mapInput: {
      type: Function as PropType<(input: InlichtingInput, inlichtingId: string)=> Inlichting>,
      required: true
    },
    mapInlichting: {
      type: Function as PropType<(vipInlichting: Inlichting)=> InlichtingInput>,
      required: true
    },

  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      isNietGekend,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted
    } = useEditableInlichting(props.inlichting, props.mapInlichting, props.mapInput)

    const inlichtingTitle = computed(() => {
      const type = inlichtingen.find(inl => inl.inlichtingType === props.inlichtingType)
      return `${type.group} (${type.label.toLowerCase()})`
    })

    const voorschriftSuggesties: string[] = voorschriften

    const voorschriftenVerplicht = computed(() => inlichtingInput?.value?.planfase?.status === PlanfaseStatus.DEFINITIEVE_VASTSTELLING ||
        inlichtingInput?.value?.planfase?.status === PlanfaseStatus.BESLUIT_TOT_GOEDKEURING)

    const planfaseOptions =
        new Map([
          ['BESLUIT_TOT_GOEDKEURING', 'Besluit tot goedkeuring'],
          ['DEFINITIEVE_VASTSTELLING', 'Definitieve vaststelling'],
          ['NATRAJECT', 'Natraject'],
          ['PLENAIRE_VERGADERING', 'Plenaire vergadering'],
          ['SCOPING', 'Scoping'],
          ['START', 'Start'],
          ['VOORLOPIGE_VASTSTELLING', 'Voorlopige vaststelling'],
          ['ADVISERING', 'Advisering']
        ])

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.beschrijving) {
        errors.set('plannaam', 'Plannaam is verplicht')
      }
      if (!inlichtingInput.value.referentie) {
        errors.set('planreferentie', 'Planreferentie is verplicht')
      }
      if (!inlichtingInput.value.planfase.status) {
        errors.set('planfase', 'Planstatus is verplicht')
      }
      if (voorschriftenVerplicht.value && (inlichtingInput.value.bestemmingen ?? []).length === 0) {
        errors.set('voorschriften', 'Stedenbouwkundige voorschriften is verplicht')
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    const addVoorschrift = (bestemming: string) => {
      voorschriftSuggesties.push(bestemming)
      inlichtingInput.value.bestemmingen.push(bestemming)
    }

    watch(() => inlichtingInput.value.inlichtingenIndicator, (newValue) => {
      if (newValue === InlichtingInputInlichtingenIndicator.NEE) {
        inlichtingInput.value.planfase = null
      } else if (newValue === InlichtingInputInlichtingenIndicator.JA && inlichtingInput.value.planfase == null) {
        inlichtingInput.value.planfase = {} as Planfase
      }
    }, { immediate: true })

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingTitle,
      voorschriftSuggesties,
      voorschriftenVerplicht,
      planfaseOptions,
      isNietGekend,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      addVoorschrift,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      formatNumber,
      formatDate
    }
  }
})
