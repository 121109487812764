import { computed, defineComponent, PropType } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  BronInformatie,
  DossierStatus,
  GemeentelijkeHeffingInlichting,
  GemeentelijkeHeffingInlichtingBelastingType,
  GemeentelijkeHeffingInput,
  GemeentelijkeHeffingInputBelastingType,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator,
  InlichtingRubriek,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'

function mapInlichtingToInput (vipInlichting?: GemeentelijkeHeffingInlichting): GemeentelijkeHeffingInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingInputInlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.GEMEENTELIJKE_HEFFING_V1,
    inlichtingType: VipInlichtingType.GEMEENTELIJKE_HEFFING,
    beschrijving: vipInlichting?.beschrijving,
    belastingType: vipInlichting?.belastingType as unknown as GemeentelijkeHeffingInputBelastingType,
    externeDocumentatie: vipInlichting?.externeDocumentatie
  } as GemeentelijkeHeffingInput
}

function mapInputToInlichting (input: GemeentelijkeHeffingInput, inlichtingId: string): GemeentelijkeHeffingInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.GEMEENTELIJKE_HEFFING,
    version: 1,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.OVERHEIDSPLANNEN,
    beschrijving: input.beschrijving,
    belastingType: input.belastingType as unknown as GemeentelijkeHeffingInlichtingBelastingType,
    externeDocumentatie: input.externeDocumentatie
  } as GemeentelijkeHeffingInlichting
}

export default defineComponent({
  name: 'GemeentelijkeHeffing',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew, VipInlichtingEdit },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted
    } = useEditableInlichting(
      props.inlichting ?? ({ inlichtingType: VipInlichtingType.GEMEENTELIJKE_HEFFING } as unknown as GemeentelijkeHeffingInlichting),
      mapInlichtingToInput,
      mapInputToInlichting)

    const inlichtingType = TypeInlichting.GemeentelijkeHeffing
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const options = new Map([
      ['ACTIVERINGSHEFFING_DECREET_GRONDBELEID', 'Activeringsheffing Decreet grond- en pandenbeleid'],
      ['BEDRIJFSBELASTING', 'Bedrijfsbelasting'],
      ['BELAST_2DE_VERBLIJF', 'Tweede verblijf'],
      ['BELAST_BANKEN', 'Banken'],
      ['BELAST_DRIJFKRACHT', 'Drijfkracht'],
      ['BELAST_INNAME_OPENB_DOMEIN', 'Inname openbaar domein (werven)'],
      ['BELAST_KAMERS', 'Kamers'],
      ['BELAST_KANTOOR', 'Kantoorruimten'],
      ['BELAST_LOGIES', 'Logies'],
      ['BELAST_NACHTWINKELS', 'Nachtwinkels en private bureaus telecommunicatie'],
      ['BELAST_ONAFGEWERKT', 'Onafgewerkte gebouwen'],
      ['BELAST_TERRAS', 'Terras (handelszaken)'],
      ['BELAST_VAKANTIEHUIS', 'Vakantie-, zomer- en weekendhuisjes en caravans'],
      ['HEFFING_LEEGSTAND_BEDRIJF', 'Leegstand bedrijven'],
      ['HEFFING_LEEGSTAND_WONING', 'Leegstand woningen en/of gebouwen'],
      ['HEFFING_ONGESCHIKT', 'Ongeschikte en onbewoonbare woningen'],
      ['MILIEUBELASTING', 'Milieubelasting'],
      ['ONTBREKENDE_PARKEERPLAATSEN', 'Ontbrekende parkeerplaatsen'],
      ['VERHAALBELASTING_OP_RIOLERING_BIJ_SPLITSING', 'Verhaalbelasting op riolering bij splitsing'],
      ['VERHAALBELAST_WEGZATE', 'Verhaalbelasting op wegzate, etc.'],
      ['HEFFING_VERKROTTING', 'Verkrotte woningen en/of gebouwen'],
      ['HEFFING_VERWAARLOZING', 'Verwaarloosde woningen, gebouwen en/of gronden'],
    ])

    const optionsPreview = new Map([
      ['ACTIVERINGSHEFFING_DECREET_GRONDBELEID', 'Gemeentelijke activeringsheffing decreet grond- en pandenbeleid'],
      ['BEDRIJFSBELASTING', 'Gemeentelijke bedrijfsbelasting (mogelijks van toepassing)'],
      ['BELAST_2DE_VERBLIJF', 'Gemeentelijke belasting op tweede verblijf (mogelijks van toepassing)'],
      ['BELAST_BANKEN', 'Gemeentelijke belasting op banken (mogelijks van toepassing)'],
      ['BELAST_DRIJFKRACHT', 'Gemeentelijke belasting op drijfkracht (mogelijks van toepassing)'],
      ['BELAST_INNAME_OPENB_DOMEIN', 'Gemeentelijke belasting voor inname openbare weg of openbaar domein (mogelijks van toepassing)'],
      ['BELAST_KAMERS', 'Gemeentelijke belasting op kamers (mogelijks van toepassing)'],
      ['BELAST_KANTOOR', 'Gemeentelijke belasting op kantoorruimten (mogelijks van toepassing)'],
      ['BELAST_LOGIES', 'Gemeentelijke belasting op logies (mogelijks van toepassing)'],
      ['BELAST_NACHTWINKELS', 'Gemeentelijke belasting op nachtwinkel (mogelijks van toepassing)'],
      ['BELAST_ONAFGEWERKT', 'Gemeentelijke belasting op onafgewerkte gebouwen'],
      ['BELAST_TERRAS', 'Gemeentelijke belasting voor terras (handelszaken) (mogelijks van toepassing)'],
      ['BELAST_VAKANTIEHUIS', 'Gemeentelijke belasting op vakantie -, zomer - en weekendhuisjes en caravans (mogelijks van toepassing)'],
      ['HEFFING_LEEGSTAND_BEDRIJF', 'Gemeentelijke heffing op leegstand bedrijven'],
      ['HEFFING_LEEGSTAND_WONING', 'Gemeentelijke heffing op leegstand woningen en/of gebouwen'],
      ['HEFFING_ONGESCHIKT', 'Gemeentelijke heffing op ongeschikte en onbewoonbare woningen'],
      ['HEFFING_VERKROTTING', 'Gemeentelijke heffing op verkrotte woningen en/of gebouwen'],
      ['HEFFING_VERWAARLOZING', 'Gemeentelijke heffing op verwaarloosde woningen, gebouwen en/of gronden'],
      ['MILIEUBELASTING', 'Gemeentelijke milieubelasting (mogelijks van toepassing)'],
      ['ONTBREKENDE_PARKEERPLAATSEN', 'Gemeentelijke belasting op ontbrekende parkeerplaatsen'],
      ['VERHAALBELASTING_OP_RIOLERING_BIJ_SPLITSING', 'Verschuldigde gemeentelijke verhaalbelasting op riolering bij splitsing'],
      ['VERHAALBELAST_WEGZATE', 'Gemeentelijke verhaalbelasting op wegzate, wegenuitrusting, aanleg trottoirs, aansluiting op rioleringsnet, aanleg riolen'],
    ])

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.belastingType) {
        errors.set('belasting-type', 'Heffings- of belastingstype is verplicht')
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      options,
      optionsPreview,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      formatNumber,
      formatDate
    }
  }
})
