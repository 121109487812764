import { InlichtingAttribuut, InlichtingDatePeriodAttribuut } from '@/infrastructure/bff-client/bff-client'
import { generateKey } from '@/infrastructure/helpers/general'
import Vue from 'vue'
import { InlichtingComponentData } from '@/components/vip-inlichting/vip-inlichting'
export default Vue.extend({
  data () {
    return {
      // Opties voor 'vl-upload' component gebruikt om bijlagen toe te voegen aan een inlichting.
      uniqueKey: generateKey()
    }
  },
  name: 'vip-inlichting-attributes',
  props: {
    inlichting: Object as ()=> InlichtingComponentData,
    inlichtingType: Number,
    attributes: Array as ()=> InlichtingAttribuut[],
    label: String,
    modDisabled: Boolean,
    template: {
      default: 'default',
      type: String
    },
    dossierId: String
  },
  computed: {
    component () {
      return `vip-inlichting-attributes-template-${this.template}`
    },
    inlichtingId (): string {
      if (this.inlichting && this.inlichting.inlichtingId) {
        return this.inlichting.inlichtingId
      }
      return ''
    }
  },
  methods: {
    makeUnique (string: string) {
      return `${string}-${this.uniqueKey}`
    },
    getAttribute (name: string): InlichtingAttribuut {
      const attribuut = this.findAttribute(this.attributes as InlichtingAttribuut[] || [], name)

      if (attribuut) {
        return attribuut
      }
    },
    getDatePeriodFromAttribute (datePeriodAttributeName: string): InlichtingAttribuut {
      return (this.getAttribute(datePeriodAttributeName) as InlichtingDatePeriodAttribuut).dateFrom
    },
    getDatePeriodUntilAttribute (datePeriodAttributeName: string): InlichtingAttribuut {
      return (this.getAttribute(datePeriodAttributeName) as InlichtingDatePeriodAttribuut).dateUntil
    },
    setAttribute (name: string, value: any) {
      const attribuut = this.findAttribute(this.attributes as InlichtingAttribuut[] || [], name)

      if (attribuut) {
        attribuut.value = value
      }
    },
    findAttribute (attributes: InlichtingAttribuut[], name: string): InlichtingAttribuut {
      for (const attribute of attributes) {
        if (attribute.name.toLocaleLowerCase() === name.toLocaleLowerCase()) {
          return attribute
        }
      }
    },
    // Event handler van de 'upload-file-added'-event van 'vl-upload' component gebruikt om bijlagen toe te voegen
    // aan een inlichting.
    onBijlageUploaded (bijlagen: File[]) {
      this.$nextTick(() => {
        this.$emit('bijlagen-to-upload-changed', bijlagen)
      })
    }
  }
})
