import { render, staticRenderFns } from "./soort-weg-v1.vue?vue&type=template&id=45e97011"
import script from "./soort-weg-v1.ts?vue&type=script&lang=ts&external"
export * from "./soort-weg-v1.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports