import { computed, defineComponent, PropType } from 'vue'
import VipInlichtingVanToepassing
  from '@/components/vip-inlichting/components/vip-inlichting-van-toepassing/vip-inlichting-van-toepassing.vue'
import {
  BronInformatie,
  DossierStatus,
  Inlichting,
  InlichtingenIndicator,
  InlichtingInputInlichtingenIndicator,
  InlichtingRubriek,
  TypeInlichting,
  VipInlichtingDiscriminator,
  VipInlichtingType,
  WettelijkeErfdienstbaarheidVanOpenbaarNutInlichting,
  WettelijkeErfdienstbaarheidVanOpenbaarNutInlichtingType,
  WettelijkeErfdienstbaarheidVanOpenbaarNutInput,
  WettelijkeErfdienstbaarheidVanOpenbaarNutInputType
} from '@/infrastructure/bff-client/bff-client'
import VipInlichtingPreviewNew
  from '@/components/vip-inlichting/new-version/components/inlichting-preview/vip-inlichting-preview-new.vue'
import { formatDate, formatNumber } from '@/infrastructure/filters/filters'
import { inlichtingen } from '@/infrastructure/constants/inlichting-types-constants'
import useEditableInlichting from '@/components/vip-inlichting/new-version/composables/editable-inlichting.composable'
import EventBus from '@/infrastructure/events/event-bus'
import VipInlichtingEdit
  from '@/components/vip-inlichting/new-version/components/inlichting-edit/vip-inlichting-edit.vue'

function mapInlichtingToInput (vipInlichting?: WettelijkeErfdienstbaarheidVanOpenbaarNutInlichting): WettelijkeErfdienstbaarheidVanOpenbaarNutInput {
  return {
    inlichtingenIndicator: vipInlichting === null ? InlichtingInputInlichtingenIndicator.NEE : vipInlichting.inlichtingenIndicator as unknown as InlichtingInputInlichtingenIndicator,
    discriminator: VipInlichtingDiscriminator.WETTELIJKE_ERFDIENSTBAARHEID_VAN_OPENBAAR_NUT_V1,
    inlichtingType: VipInlichtingType.WETTELIJKE_ERFDIENSTBAARHEID_VAN_OPENBAAR_NUT,
    type: vipInlichting?.type as unknown as WettelijkeErfdienstbaarheidVanOpenbaarNutInputType,
    beheerder: vipInlichting?.beheerder,
    omschrijving: vipInlichting?.omschrijving,
    externeDocumentatie: vipInlichting?.externeDocumentatie
  } as WettelijkeErfdienstbaarheidVanOpenbaarNutInput
}

function mapInputToInlichting (input: WettelijkeErfdienstbaarheidVanOpenbaarNutInput, inlichtingId: string): WettelijkeErfdienstbaarheidVanOpenbaarNutInlichting {
  return {
    inlichtingId,
    inlichtingenIndicator: input.inlichtingenIndicator as unknown as InlichtingenIndicator,
    inlichtingType: VipInlichtingType.WETTELIJKE_ERFDIENSTBAARHEID_VAN_OPENBAAR_NUT,
    version: 1,
    bronInformatie: {
      informatieverstrekker: 'Lokaal bestuur',
      laatsteWijzigingTijdstip: new Date()
    } as BronInformatie,
    kaarten: [],
    rubriek: InlichtingRubriek.OVERHEIDSPLANNEN,
    type: input.type as unknown as WettelijkeErfdienstbaarheidVanOpenbaarNutInlichtingType,
    beheerder: input.beheerder,
    omschrijving: input.omschrijving,
    externeDocumentatie: input.externeDocumentatie
  } as WettelijkeErfdienstbaarheidVanOpenbaarNutInlichting
}

export default defineComponent({
  name: 'WettelijkeErfdienstbaarheidVanOpenbaarNut',
  components: { VipInlichtingVanToepassing, VipInlichtingPreviewNew, VipInlichtingEdit },
  props: {
    inlichting: {
      type: Object as PropType<Inlichting>,
      required: false
    },
    dossierId: {
      type: String,
      required: true
    },
    dossierStatus: {
      type: Number as PropType<DossierStatus>,
      required: false
    },
    canDelete: Boolean
  },
  setup (props, { emit }) {
    const {
      refs,
      inlichtingKey,
      vipInlichting,
      inlichtingInput,
      canEdit,
      validationErrors,
      accordion,
      saving,
      addedBijlagen,
      isNietGekend,
      bijlageUploaded,
      toggleIndicator,
      inlichtingToggled,
      cancelEdit,
      saveInlichting,
      validationFailed,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted
    } = useEditableInlichting(
      props.inlichting ?? ({ inlichtingType: VipInlichtingType.WETTELIJKE_ERFDIENSTBAARHEID_VAN_OPENBAAR_NUT } as unknown as WettelijkeErfdienstbaarheidVanOpenbaarNutInlichting),
      mapInlichtingToInput,
      mapInputToInlichting)

    const inlichtingType = TypeInlichting.WettelijkeErfdienstbaarheidVanOpenbaarNut
    const inlichtingTitle = computed(() => inlichtingen.find(inl => inl.inlichtingType === inlichtingType).label)

    const options = new Map([
      ['BOUWHOOGTEBEPERKING', 'Bouwhoogtebeperking'],
      ['BOUWVERBOD_GRENS', 'Bouwverbod binnen 10 meter van de landsgrens of 5 meter van de grensweg'],
      ['BOUWVERBOD_LUCHTHAVENS', 'Bouwverbod in nabijheid van luchthavens'],
      ['BOUWVRIJE_STROOK_AUTOSNELWEG', 'Bouwvrije strook langs autosnelweg'],
      ['BOUWVRIJE_STROOK_GEWESTWEG', 'Bouwvrije strook langs gewestweg'],
      ['BOVENGRONDSE_INNEMING_HOOGSPANNING', 'Bovengrondse inneming voor hoogspanningsnet'],
      ['ERFDIENSTBARE_STROOK_SPOORWEG_VOOR_OPRUIMING_VAN_STRUIKGEWAS', 'Erfdienstbare strook naast (buurt)spoorwegen voor opruiming van struikgewas'],
      ['ERFDIENSTBARE_STROOK_WATERLOOP_VOOR_RUIMINGSWERKEN', 'Erfdienstbare strook langs waterloop (2de en 3de categorie) voor ruimingswerken'],
      ['GEMEENTEWEG_PRIVAAT', 'Gemeenteweg op private eigendom'],
      ['ONDERGRONDSE_INNEMING_AFVALWATER', 'Ondergrondse inneming voor aanleg van afvalwatercollector'],
      ['ONDERGRONDSE_INNEMING_DRINKWATER', 'Ondergrondse inneming voor plaatsing van elektriciteitsleidingen'],
      ['ONDERGRONDSE_INNEMING_ELECTRICITEIT', 'Ondergrondse riolering'],
      ['ONDERGRONDSE_INNEMING_GAS', 'Ondergrondse inneming voor vervoer van gasachtige producten'],
      ['ONDERGRONDSE_INNEMING_STADSWATERLOPEN', 'Ondergrondse inneming voor stadswaterlopen'],
      ['ONDERGRONDSE_RIOLERING', 'Ondergrondse inneming voor vervoer van drinkwater'],
      ['PERCEEL_NIET_AAN_OPENBARE_WEG', 'Perceel niet gelegen aan openbare weg'],
      ['WARMTENETTEN', 'Warmtenetten']
    ])

    const validateInput = (): Map<string, string> => {
      const errors = new Map<string, string>()
      if (!inlichtingInput.value.type) {
        errors.set('type', 'Erfdienstbaarheidstype is verplicht')
      }
      return errors
    }

    const saved = (inlichtingId: string) => {
      saveInlichting(inlichtingId)
      emit('saved', vipInlichting.value)
      inlichtingToggled(true)
    }

    const removed = () => {
      emit('removed', vipInlichting.value.inlichtingId)
      EventBus.$emit('close-modal', 'vl-modal-backdrop')
    }

    const cancelled = () => {
      cancelEdit()
      emit('cancelled')
    }

    return {
      vipInlichting,
      inlichtingInput,
      validationErrors,
      saving,
      accordion,
      refs,
      inlichtingKey,
      canEdit,
      addedBijlagen,
      inlichtingType,
      inlichtingTitle,
      options,
      isNietGekend,
      bijlageUploaded,
      inlichtingToggled,
      toggleIndicator,
      validateInput,
      validationFailed,
      saved,
      removed,
      cancelled,
      downloadUrl,
      bijlagenSaved,
      bijlageDeleted,
      formatNumber,
      formatDate
    }
  }
})
