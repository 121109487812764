import { defineComponent, PropType, ref, watch } from 'vue'
import { BijkomendeDocumentatie } from '@/infrastructure/bff-client/bff-client'

export default defineComponent({
  name: 'bijkomende-documentatie',
  props: {
    bijkomendeDocumentatie: Object as PropType<BijkomendeDocumentatie>,
    documentatieIndex: Number,
    inlichtingKey: String,
    validationErrors: Map<string, string>,
    saving: Boolean
  },
  setup (props, { emit }) {
    const bijkomendeDocumentatieInput = ref(props.bijkomendeDocumentatie as BijkomendeDocumentatie)
    watch(() => bijkomendeDocumentatieInput.value.titel, (newValue) => {
      const input = structuredClone(bijkomendeDocumentatieInput.value)
      input.titel = newValue
      emit('change', input)
    })
    watch(() => bijkomendeDocumentatieInput.value.classificatie, (newValue) => {
      const input = structuredClone(bijkomendeDocumentatieInput.value)
      input.classificatie = newValue
      emit('change', input)
    })
    watch(() => bijkomendeDocumentatieInput.value.omschrijving, (newValue) => {
      const input = structuredClone(bijkomendeDocumentatieInput.value)
      input.omschrijving = newValue
      emit('change', input)
    })
    watch(() => bijkomendeDocumentatieInput.value.link, (newValue) => {
      const input = structuredClone(bijkomendeDocumentatieInput.value)
      input.link = newValue
      emit('change', input)
    })
    return {
      bijkomendeDocumentatieInput,
    }
  }
})
