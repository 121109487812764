import { render, staticRenderFns } from "./besluit-burgemeester-inzake-openbare-veiligheid.vue?vue&type=template&id=0e5a751d"
import script from "./besluit-burgemeester-inzake-openbare-veiligheid.ts?vue&type=script&lang=ts&external"
export * from "./besluit-burgemeester-inzake-openbare-veiligheid.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports