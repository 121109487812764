import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import {
  DossierStatus,
  Inlichting,
  InlichtingenStatus, TypeInlichting,
} from '@/infrastructure/bff-client/bff-client'
import { useUserStore } from '@/infrastructure/stores/user-store'
import { BffClientFactory } from '@/infrastructure/bff-client/bff-client-handler'
import { formatDate } from '@/infrastructure/filters/filters'
import { inlichtingen, Hoedanigheid } from '@/infrastructure/constants/inlichting-types-constants'
import { catchBffError, errorAlert } from '@/infrastructure/helpers/general'

export default defineComponent({
  name: 'vip-inlichting-preview-new',
  methods: { formatDate },
  props: {
    dossierId: String,
    dossierStatus: Number as PropType<DossierStatus>,
    isVerplicht: Boolean,
    inlichtingLabel: String,
    vipInlichting: {
      type: Object as PropType<Inlichting>,
      required: true
    },
    inlichtingType: Number as PropType<TypeInlichting>,
    afwijkendVoorNietVanToepassing: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup (props, context) {
    const length = ref(0) // Fix random length is not defined error
    const isValidated = ref(false)
    const userStore = useUserStore()
    const inlichting = ref(props.vipInlichting as Inlichting)
    const informatieVerstrekker = ref((props.vipInlichting as Inlichting)?.bronInformatie?.informatieverstrekker ?? '')
    const bronLaatsteWijziging = ref((props.vipInlichting as Inlichting)?.bronInformatie?.laatsteWijzigingTijdstip as Date ?? '')

    const userCanEdit = computed(() => userStore.userCanEditDossier)
    const userIsOperationeelBeheerder = computed(() => userStore.userIsAdmin)
    const isVerrijkingGefaald = computed(() => (props.vipInlichting as Inlichting)?.inlichtingenStatus === InlichtingenStatus.VERRIJKING_DOOR_VIP_NIET_GESLAAGD)
    const hasKaarten = computed(() => (props.vipInlichting as Inlichting)?.kaarten?.length > 0)

    const inlichtingInfo = computed(() => inlichtingen.find(inl => inl.inlichtingType === props.inlichtingType))
    const isReadOnly = computed(() => inlichtingInfo.value?.hoedanigheid === Hoedanigheid.READ_ONLY)
    const herbevragingIsVisible = computed(() => {
      return inlichtingInfo.value?.hoedanigheid !== Hoedanigheid.FEED && userStore.userIsAdmin
    })

    const openInteractieveKaart = () => {
      for (const url of (props.vipInlichting as Inlichting).kaarten?.map(kaart => kaart.url)) {
        window.open(url, '_blank')
      }
    }

    const herbevraagInlichtingType = () => {
      BffClientFactory().dossierInlichtingen_HerbevraagInlichtingType(props.dossierId, props.inlichtingType)
        .then(() => {
          context.emit('herbevraagd')
        })
        .catch(error => {
          if (error.title != null) {
            errorAlert('herbevraag-inlichting', 'InlichtingType is al verrijkt door lokaal bestuur', '', true)
          } else {
            catchBffError(error, 'herbevraag-inlichting', true, true)
          }
        })
    }

    onMounted(() => {
      isValidated.value = props.dossierStatus === DossierStatus.Gevalideerd
    })

    return {
      inlichting,
      informatieVerstrekker,
      bronLaatsteWijziging,
      isValidated,
      userCanEdit,
      userIsOperationeelBeheerder,
      isVerrijkingGefaald,
      length,
      hasKaarten,
      isReadOnly,
      herbevragingIsVisible,
      openInteractieveKaart,
      herbevraagInlichtingType
    }
  }
})
