import { FileParameter } from '@/infrastructure/bff-client/bff-client'
import { BffClientFactory } from '@/infrastructure/bff-client/bff-client-handler'

export interface BijlageUploadResult {
  bijlageName: string,
  bijlageId: string
}

export class VipInlichtingBijlagenService {
  async save (dossierId: string, inlichtingId: string, bijlagenToUpload: File[]): Promise<BijlageUploadResult[]> {
    if (!bijlagenToUpload || bijlagenToUpload.length === 0) {
      return []
    }
    const promises = bijlagenToUpload.map(async (bijlage: File) => {
      const bijlageFile = {
        data: bijlage,
        fileName: bijlage.name
      } as FileParameter

      const bijlageId = await BffClientFactory().dossierInlichtingenBijlagen_Post(dossierId, inlichtingId, bijlageFile)
      return { bijlageName: bijlage.name, bijlageId }
    })
    return await Promise.all(promises)
  }
}
